import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import ResidentTeamSection from "../components/static-sections/ResidentTeamSection"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import GalleryImageLargeSection from "../components/dynamic-sections/GalleryImageLargeSection"

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import SearchIcon from "../../assets/search-icon.svg"
import ApartmentIcon from "../../assets/apartment-icon.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const ResidentTeamPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/resident-team/",
          name: "Resident Team | Norhart",
          image: `${config.siteMetadata.siteUrl}/resident-team/norhart-resident-team-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Resident Team | Norhart"
      description="Norhart builds and rents exceptional luxury apartments in Forest Lake, Blaine, Circle Pines, Lexington, and Oakdale, Minnesota. We love creating a better way for people to live!"
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/resident-team/norhart-resident-team-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/resident-team/norhart-resident-team-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Resident Team"
        subtitle="Creating an Exceptional Resident Experience"
        imageTitle="Norhart Property Team"
        image="/resident-team/norhart-resident-team-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Welcome Home"
        tagLine="Lease One Of Our Norhart Apartments Today"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <ResidentTeamSection colorPalette={colorPalette} />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Resdient Team"
        title="Let Us Show You Your New Home"
        subTitle="Our Resident Managers are here to ensure selecting your new home is an exceptional experience right from your first meeting. #NorhartCulture"
        image="/resident-team/norhart-resident-team.png"
        imageAlt="Norhart Resdient Team"
        buttonName="Apply Today"
        buttonUrl="https://norhart.twa.rentmanager.com/applynow"
        textColor="#FFFFFF"
        asoEffect="fade-in"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Residents Say It Best"
        starRating="yes"
        subTitle={`"If there’s a mistake they fix it. The management team is AMAZING!! The type of people you want to be friends with. The apartments are absolutely gorgeous as well!" - Viviana, Norhart Resident`}
        image="/support/norhart-resident-reviews-2.png"
        imageAlt="Norhart Resident Reviews"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Maintenance"
        title="We Keep Your Home Safe and Enjoyable"
        subTitle="Our crack maintenance team cleans, performs safety checks, and completes repairs for our valued Norhart residents! #WooHoo"
        image="/resident-team/norhart-mainteance-team.png"
        imageAlt="Join The Norhart Crew"
        buttonName="Submit a Service Request"
        buttonUrl="https://norhart.twa.rentmanager.com"
        textColor="#FFFFFF"
        asoEffect="fade-in"
      />

      <GalleryImageLargeSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Resident Team WorkLife"
        title="We Embrace Teaminess"
        subtitle="Norhart Resident Team (Onsite and Remote)... "
        imageTitleOne="Norhart Resident Team Image 1"
        imageOne="/resident-team/gallery/1.jpg"
        imageTitleTwo="Norhart Resident Team Image 2"
        imageTwo="/resident-team/gallery/2.jpg"
        imageTitleThree="Norhart Resident Team Image 3"
        imageThree="/resident-team/gallery/3.jpg"
        imageTitleFour="Norhart Resident Team Image 4"
        imageFour="/resident-team/gallery/4.png"
        imageTitleFive="Norhart Resident Team Image 5"
        imageFive="/resident-team/gallery/5.jpg"
        imageTitleSix="Norhart Resident Team Image 6"
        imageSix="/resident-team/gallery/6.jpg"
        imageTitleSeven="Norhart Resident Team Image 7"
        imageSeven="/resident-team/gallery/7.jpg"
        imageTitleEight="Norhart Resident Image 8"
        imageEight="/resident-team/gallery/8.jpg"
        imageTitleNine="Norhart Resident Team Image 9"
        imageNine="/resident-team/gallery/9.jpg"
        imageTitleTen="Norhart Resident Team Image 10"
        imageTen="/resident-team/gallery/10.jpg"
        imageTitleEleven="Norhart Resident Team Image 11"
        imageEleven="/resident-team/gallery/11.jpg"
        imageTitleTweleve="Norhart Resident Team Image 12"
        imageTweleve="/resident-team/gallery/12.jpg"
        colorPalette={colorPalette}
        buttonName="Norhart Culture Instagram"
        buttonUrl="https://www.instagram.com/norhartculture/"
        badge={false}
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        titleTopLeft="Career Opportunities"
        badgeTitle="Now Hiring"
        title="Join Our A-Team"
        subtitle="Built into Norhart's DNA is the obsession to improve our customer's lives. We love elevating our people's skills, creativity, and drive to build the exceptional."
        imageTitle="Norhart Technology"
        image="/resident-team/norhart-careers-join-our-team.png"
        buttonName="Learn More"
        buttonUrl="/careers/"
        colorPalette={colorPalette}
        colSizeOne={4}
        colSizeTwo={8}
        bottomMargin={0}
        asoEffect="fade-in"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Residents Say It Best"
        starRating="yes"
        subTitle={`"The Encore Apartments by Norhart are brand new and beautiful!  Located in friendly Forest Lake, they are easy to access and super convenient. Multiple floor plans that have a luxurious feel, with amenities that include a pool area and fitness center" - Lori, Norhart Resident`}
        image="/support/norhart-resident-reviews-1.png"
        imageAlt="Norhart Resident Reviews"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="What Drives Us"
        subtitle="Our culture and values propel us forward. Always be respectful, results drive success, we love to cultivate innovation, and above all, we succeed together. We fail together. That's how we roll!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default ResidentTeamPage
